import { Moment } from 'moment';
import {
    Cursor,
    DateTimeRange,
    FilterCriterion,
    StandingTimeUpdate,
    TransportOrder,
    TransportOrders,
} from '../../reducers/transportOrders/types';

export const UPDATE_TRANSPORT_ORDERS_PENDING = 'transportOrders/UPDATE_TRANSPORT_ORDERS_PENDING';
export const UPDATE_TRANSPORT_ORDERS = 'transportOrders/UPDATE_TRANSPORT_ORDERS';
export const LOAD_MORE_TRANSPORT_ORDERS = 'transportOrders/LOAD_MORE_TRANSPORT_ORDERS';
export const UPDATE_TRANSPORT_ORDER = 'transportOrders/UPDATE_TRANSPORT_ORDER';
export const SELECT_TRANSPORT_ORDER = 'transportOrders/SELECT_TRANSPORT_ORDER';
export const SUCCESSFUL_DELIVERY_UNLOADED = 'transportOrders/SUCCESSFUL_DELIVERY_UNLOADED';
export const SUCCESSFUL_TRANSPORT_ORDER_LOADED = 'transportOrders/SUCCESSFUL_TRANSPORT_ORDER_LOADED';
export const SUCCESSFUL_TRANSPORT_ORDER_WAREHOUSE_STANDING_TIMES_EDITED =
    'transportOrders/SUCCESSFUL_TRANSPORT_ORDER_WAREHOUSE_STANDING_TIMES_EDITED';
export const SUCCESSFUL_TRANSPORT_ORDER_CANCELLED = 'transportOrders/SUCCESSFUL_TRANSPORT_ORDER_CANCELLED';
export const SELECT_DATETIME_RANGE = 'transportOrders/SELECT_DATETIME_RANGE';
export const CHANGE_CARRIER_FILTER = 'transportOrders/CHANGE_CARRIER_FILTER';
export const SET_LAST_REFRESH_TIME = 'transportOrders/SET_LAST_REFRESH_TIME';
export const UPDATE_SEARCH_FILTER = 'transportOrders/UPDATE_SEARCH_FILTER';
export const SET_CURSOR = 'transportOrders/SET_CURSOR';
export const UPDATE_NUMBER_OF_PENDING_TRANSPORT_ORDERS = 'transportOrders/UPDATE_NUMBER_OF_PENDING_TRANSPORT_ORDERS';
export const SET_STANDING_TIME_EDIT_MODE = 'transportOrders/SET_STANDING_TIME_EDIT_MODE';

export interface UpdateTransportOrdersPendingAction {
    type: typeof UPDATE_TRANSPORT_ORDERS_PENDING;
}

export interface UpdateTransportOrdersAction {
    type: typeof UPDATE_TRANSPORT_ORDERS;
    payload: TransportOrders;
}

export interface UpdateNumberOfPendingTransportOrdersAction {
    type: typeof UPDATE_NUMBER_OF_PENDING_TRANSPORT_ORDERS;
    payload: TransportOrders;
}

export interface LoadMoreTransportOrdersAction {
    type: typeof LOAD_MORE_TRANSPORT_ORDERS;
    payload: TransportOrders;
}

export interface UpdateTransportOrderAction {
    type: typeof UPDATE_TRANSPORT_ORDER;
    payload: TransportOrder;
}

export interface SetCursorAction {
    type: typeof SET_CURSOR;
    payload: Cursor;
}

export interface SuccessfulTransportOrderLoadedAction {
    type: typeof SUCCESSFUL_TRANSPORT_ORDER_LOADED;
    payload: TransportOrder;
}

export interface SuccessfulTransportOrderCancelled {
    type: typeof SUCCESSFUL_TRANSPORT_ORDER_CANCELLED;
    payload: string;
}

export interface SuccessfulDeliveryUnloadedAction {
    type: typeof SUCCESSFUL_DELIVERY_UNLOADED;
    payload: {
        transportOrder: TransportOrder;
        destinationId: number;
    };
}

export interface SuccessfulTransportOrderWarehouseStandingTimesUpdatedAction {
    type: typeof SUCCESSFUL_TRANSPORT_ORDER_WAREHOUSE_STANDING_TIMES_EDITED;
    payload: {
        transportOrder: TransportOrder;
        warehouseId: number;
        standingTimeUpdate: StandingTimeUpdate;
    };
}

export interface SelectTransportOrderAction {
    type: typeof SELECT_TRANSPORT_ORDER;
    payload: string | undefined;
}

export interface SelectDateTimeRangeAction {
    type: typeof SELECT_DATETIME_RANGE;
    payload: DateTimeRange;
}

export interface ChangeCarrierFilterAction {
    type: typeof CHANGE_CARRIER_FILTER;
    payload: {
        activeFilter: FilterCriterion;
    };
}

export interface SetLastRefreshAction {
    type: typeof SET_LAST_REFRESH_TIME;
    payload: Moment;
}

export interface UpdateSearchFilterAction {
    type: typeof UPDATE_SEARCH_FILTER;
    payload: string;
}

export interface SetStandingTimeEditMode {
    payload: { editMode: boolean };
    type: typeof SET_STANDING_TIME_EDIT_MODE;
}

export type TransportOrderActions =
    UpdateTransportOrdersAction
    | UpdateTransportOrderAction
    | UpdateTransportOrdersPendingAction
    | UpdateNumberOfPendingTransportOrdersAction
    | SelectTransportOrderAction
    | SuccessfulTransportOrderLoadedAction
    | SuccessfulDeliveryUnloadedAction
    | SuccessfulTransportOrderWarehouseStandingTimesUpdatedAction
    | SuccessfulTransportOrderCancelled
    | SelectDateTimeRangeAction
    | ChangeCarrierFilterAction
    | SetCursorAction
    | LoadMoreTransportOrdersAction
    | SetLastRefreshAction
    | UpdateSearchFilterAction
    | SetStandingTimeEditMode;
