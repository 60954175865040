import { isEqual } from 'lodash';
import moment from 'moment';
import {
    FINISHED_FETCH_TRANSPORT_ORDER_REPORT,
    STARTED_FETCH_TRANSPORT_ORDER_REPORT,
} from '../../actions/transportOrder/report/AuditorReportDownloadDialog.action';
import { TransportOrderReportActions } from '../../actions/auditor/types';
import {
    CHANGE_CARRIER_FILTER,
    LOAD_MORE_TRANSPORT_ORDERS,
    SET_STANDING_TIME_EDIT_MODE,
    SELECT_DATETIME_RANGE,
    SELECT_TRANSPORT_ORDER,
    SET_CURSOR,
    SET_LAST_REFRESH_TIME,
    SUCCESSFUL_DELIVERY_UNLOADED,
    SUCCESSFUL_TRANSPORT_ORDER_CANCELLED,
    SUCCESSFUL_TRANSPORT_ORDER_LOADED,
    SUCCESSFUL_TRANSPORT_ORDER_WAREHOUSE_STANDING_TIMES_EDITED,
    TransportOrderActions,
    UPDATE_NUMBER_OF_PENDING_TRANSPORT_ORDERS,
    UPDATE_SEARCH_FILTER,
    UPDATE_TRANSPORT_ORDER,
    UPDATE_TRANSPORT_ORDERS,
    UPDATE_TRANSPORT_ORDERS_PENDING,
} from '../../actions/transportOrder/types';
import {
    FilterCriterion,
    StandingTimeUpdate,
    TransportOrder,
    TransportOrders,
    TransportOrdersState,
    TransportOrderStatus,
} from './types';
import { calculateDurationInMinutes } from '../../../api/mapping';

const initialState: TransportOrdersState = {
    transportOrders: [],
    numberOfPendingTransportOrders: 0,
    isLoading: true,
    isDownloadingReport: false,
    selectedTransportOrder: undefined,
    selectedDateTimeRange: { from: moment(), to: moment() },
    filterCriterion: FilterCriterion.CARRIER_ACTION_REQUIRED,
    cursor: undefined,
    searchFilter: '',
    lastRefresh: moment(),
    isStandingTimeEditModeEnabled: false,
};

function selectTransportOrder(state: TransportOrdersState, transportOrderId: string | undefined): TransportOrdersState {
    if (transportOrderId) {
        const selectedTransportOrder = state.transportOrders.find(transportOrder => {
            return transportOrder.id === transportOrderId;
        });
        return selectedTransportOrder
            ? { ...state, selectedTransportOrder, isStandingTimeEditModeEnabled: false }
            : { ...state, isStandingTimeEditModeEnabled: false };
    }
    return { ...state, selectedTransportOrder: undefined, isStandingTimeEditModeEnabled: false };
}

function updateDeliveriesUnloaded(state: TransportOrdersState,
    transportOrder: TransportOrder, destinationId: number): TransportOrdersState {
    const updatedDestinations = transportOrder.unloadedDestinations.concat(destinationId);

    const updatedTransportOrder: TransportOrder = {
        ...transportOrder,
        unloadedDestinations: updatedDestinations,
        status: isEqual(transportOrder.deliveryPlan?.map(item => item.warehouseId), updatedDestinations) ?
            TransportOrderStatus.UNLOADED : TransportOrderStatus.PARTIALLY_UNLOADED,
    };

    return updateTransportOrder(state, updatedTransportOrder);
}

function updateTransportOrderWarehouseStandingTimes(
    state: TransportOrdersState,
    transportOrder: TransportOrder,
    warehouseId: number,
    standingTimeUpdate: StandingTimeUpdate,
): TransportOrdersState {
    const standingTimesForTransportOrder = { ...transportOrder.standingTimes };
    const oldStandingTimeForWarehouse = standingTimesForTransportOrder[warehouseId];

    const newStart = standingTimeUpdate.start;
    const newEnd = standingTimeUpdate.end;
    const newDurationInMinutes = calculateDurationInMinutes(newStart, newEnd)!;
    const newExceedanceInMinutes = newDurationInMinutes - oldStandingTimeForWarehouse.budgetInMinutes!;
    const newStandingTimeForWarehouse = {
        ...oldStandingTimeForWarehouse,
        start: newStart,
        end: newEnd,
        durationInMinutes: newDurationInMinutes,
        exceedanceInMinutes: newExceedanceInMinutes > 0 ? newExceedanceInMinutes : undefined,
    };

    const updatedTransportOrder: TransportOrder = {
        ...transportOrder,
        standingTimes: {
            ...standingTimesForTransportOrder,
            [warehouseId]: newStandingTimeForWarehouse,
        },
    };

    return updateTransportOrder(state, updatedTransportOrder);
}

function updateTransportOrder(state: TransportOrdersState,
    updatedTransportOrder: TransportOrder): TransportOrdersState {

    const transportOrderToUpdateIndex = state.transportOrders
        .findIndex(transportOrder => transportOrder.id === updatedTransportOrder.id);
    const transportOrders = [...state.transportOrders];
    transportOrders[transportOrderToUpdateIndex] = updatedTransportOrder;

    let selectedTransportOrder = state.selectedTransportOrder;
    if (selectedTransportOrder && selectedTransportOrder.id === updatedTransportOrder.id) {
        selectedTransportOrder = updatedTransportOrder;
    }

    return {
        ...state,
        transportOrders,
        selectedTransportOrder,
    };
}

function updateTransportOrders(state: TransportOrdersState,
    updatedTransportOrders: TransportOrders): TransportOrdersState {
    let selectedTransportOrder = state.selectedTransportOrder;
    if (selectedTransportOrder) {
        const newSelectedTransportOrder = updatedTransportOrders
            .find(transportOrder => transportOrder.id === selectedTransportOrder?.id);
        selectedTransportOrder = newSelectedTransportOrder ? newSelectedTransportOrder : selectedTransportOrder;
    }

    return {
        ...state,
        transportOrders: updatedTransportOrders,
        selectedTransportOrder,
        isLoading: false,
    };

}

function removeCancelledTransportOrder(state: TransportOrdersState,
    cancelledTransportOrderId: string): TransportOrdersState {
    const transportOrders = [...state.transportOrders]
        .filter(transportOrder => transportOrder.id !== cancelledTransportOrderId);
    const selectedTransportOrder =
        state?.selectedTransportOrder?.id === cancelledTransportOrderId ? undefined : state.selectedTransportOrder;
    return {
        ...state,
        selectedTransportOrder,
        transportOrders,
    };
}

// eslint-disable-next-line complexity
export default function transportOrdersReducer(
    state: TransportOrdersState = initialState,
    action: TransportOrderActions | TransportOrderReportActions,
): TransportOrdersState {
    switch (action.type) {
        case UPDATE_TRANSPORT_ORDERS_PENDING:
            return {
                ...state,
                isLoading: true,
            };
        case UPDATE_TRANSPORT_ORDERS:
            return updateTransportOrders(state, action.payload);
        case UPDATE_NUMBER_OF_PENDING_TRANSPORT_ORDERS:
            const lastRefresh = state.lastRefresh;
            const numberOfPendingTransportOrders =
                action.payload
                    .filter(transportOrder => transportOrder.modifiedAt.isAfter(lastRefresh)).length;
            return {
                ...state,
                numberOfPendingTransportOrders,
            };
        case LOAD_MORE_TRANSPORT_ORDERS:
            return {
                ...state,
                transportOrders: [...state.transportOrders.concat(action.payload)],
            };
        case UPDATE_TRANSPORT_ORDER:
            return updateTransportOrder(state, action.payload);
        case SELECT_TRANSPORT_ORDER:
            return selectTransportOrder(state, action.payload);
        case SUCCESSFUL_TRANSPORT_ORDER_LOADED:
            return updateTransportOrder(state,
                { ...action.payload, status: TransportOrderStatus.LOADED });
        case SUCCESSFUL_DELIVERY_UNLOADED:
            return updateDeliveriesUnloaded(state, action.payload.transportOrder, action.payload.destinationId);
        case SUCCESSFUL_TRANSPORT_ORDER_WAREHOUSE_STANDING_TIMES_EDITED:
            return updateTransportOrderWarehouseStandingTimes(state,
                action.payload.transportOrder, action.payload.warehouseId, action.payload.standingTimeUpdate);
        case SUCCESSFUL_TRANSPORT_ORDER_CANCELLED:
            return removeCancelledTransportOrder(state, action.payload);
        case STARTED_FETCH_TRANSPORT_ORDER_REPORT:
            return { ...state, isDownloadingReport: true };
        case FINISHED_FETCH_TRANSPORT_ORDER_REPORT:
            return { ...state, isDownloadingReport: false };
        case SELECT_DATETIME_RANGE:
            return { ...state, selectedDateTimeRange: action.payload };
        case CHANGE_CARRIER_FILTER:
            return { ...state, filterCriterion: action.payload.activeFilter };
        case SET_CURSOR:
            return { ...state, cursor: action.payload };
        case SET_LAST_REFRESH_TIME:
            return { ...state, lastRefresh: action.payload, numberOfPendingTransportOrders: 0 };
        case UPDATE_SEARCH_FILTER:
            return { ...state, searchFilter: action.payload };
        case SET_STANDING_TIME_EDIT_MODE:
            return {
                ...state,
                isStandingTimeEditModeEnabled: action.payload.editMode,
            };
        default:
            return state;
    }
}

