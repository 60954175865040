import { AccessToken } from '../../configuration';
import { ApiRole } from '../app/actions/types';
import { AuthorizationError } from './Errors/authorizationError';
import { ForbiddenError } from './Errors/forbiddenError';
import { NotFoundError } from './Errors/notFoundError';

const getAuthorization = (token: AccessToken) => `Bearer ${token}`;

export const XLSX_FILE_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
export const OTC_ROLE_HEADER = 'Otc-Role';

/* eslint-disable @typescript-eslint/naming-convention */
export const getFetchOptions = (role?: ApiRole, token?: AccessToken, method = 'GET') => ({
    method,
    headers: {
        Authorization: token ? getAuthorization(token) : '',
        accept: 'application/json',
        'Content-Type': 'application/json',
        ...(role && { [OTC_ROLE_HEADER]: role }),
    },
});

export const getFetchOptionsXLSX = (role: ApiRole, token?: AccessToken) => ({
    method: 'GET',
    headers: {
        Authorization: token ? getAuthorization(token) : '',
        accept: XLSX_FILE_TYPE,
        [OTC_ROLE_HEADER]: role,
    },
});
/* eslint-enable @typescript-eslint/naming-convention */

const rejectUnauthorizedRequests = (response: Response) => {
    if (response.status === 401) {
        throw new AuthorizationError('401 Unauthorized');
    }
    return response;
};

const rejectForbiddenRequests = (response: Response) => {
    if (response.status === 403) {
        throw new ForbiddenError('403 Forbidden');
    }
    return response;
};

const okOrReject = (response: Response): Promise<Response> => {
    if (!response.ok) {
        return reject(response);
    }
    return Promise.resolve(response);
};

const reject = (response: Response): Promise<never> =>
    response.json()
        .catch((error) => {
            throw new ApiError(`An Error occurred during request but response body could not be parsed: "${error}"`, response);
        })
        .then((json: ProblemResponse) => {
            throw new ProblemError(json, response);
        });

export class ApiError extends Error {
    readonly statusCode: number;
    readonly url: string;

    constructor(message: string, response: Response) {
        super(message);
        this.name = 'ApiError';
        this.statusCode = response.status;
        this.url = response.url;
    }
}

export class ProblemError extends ApiError {
    detail: string;
    code?: string;

    constructor(problem: ProblemResponse, response: Response) {
        super(`${problem.title} (status: ${problem.status}): ${problem.detail}`, response);
        this.name = 'ProblemError';
        this.detail = problem.detail;
        this.code = problem.code;
    }
}

// see https://opensource.zalando.com/problem/schema.yaml
export interface ProblemResponse {
    type: string;
    title: string;
    status: number;
    detail: string;
    instance: string;
    code?: string;
}

const rejectNotFoundRequests = (response: Response) => {
    if (response.status === 404) {
        throw new NotFoundError('404 Not found');
    }
    return response;
};

const fetchJSON = (url = '', opts: RequestInit = {}): Promise<unknown> => {
    return fetchResponse(url, opts).then(response => response.json());
};

export const fetchResponse = (url = '', options: RequestInit = {}) => {
    return fetch(url, options)
        .then(rejectUnauthorizedRequests)
        .then(rejectForbiddenRequests)
        .then(rejectNotFoundRequests)
        .then(okOrReject);
};

export default fetchJSON;
