import { saveAs } from 'file-saver';
import { Dispatch } from 'redux';
import Notification from '@rio-cloud/rio-uikit/lib/es/Notification';
import { AccessToken, getAccessToken } from '../../../../../configuration';
import { State } from '../../../../../types';
import { authenticatedGetXLSX } from '../../../../api/authenticatedFetches/authenticatedFetches';
import { XLSX_FILE_TYPE } from '../../../../api/fetchJSON';
import { FetchTransportOrderQueryOptions } from '../../../../api/types';
import { convertToQueryParameters, mapRoleToApiRole } from '../../../mapper/api/transportOrderToExternal';
import { getSelectedWarehouse } from '../../../selectors/Warehouses.selector';
import { ApiRole } from '../../types';
import { FinishedFetchTransportOrderReportAction, StartedFetchTransportOrderReportAction } from '../../auditor/types';
import { Role } from '../../../types';

export function convertContentToBlob(content: string, type: string) {
    const byteCharacters = atob(content);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type });
}

export function fetchReportAndSave(path: string, token: AccessToken, role: ApiRole): Promise<void> {
    return authenticatedGetXLSX(path, token, role)
        .then((transportOrdersReport) => transportOrdersReport.text())
        .then((reportString) => {
            const reportBlob = convertContentToBlob(reportString, XLSX_FILE_TYPE);
            saveAs(reportBlob, 'transport-orders.xlsx');
        })
        .catch((err: Error) => {
            Notification.error(err.message);
        });
}

export const fetchReport =
    (queryOptions: FetchTransportOrderQueryOptions = {}, role: Role) =>
        (dispatch: Dispatch, getStore: () => State): Promise<unknown> => {
            dispatch(startedFetchTransportOrderReport());
            const store = getStore();
            const selectedWarehouse = getSelectedWarehouse(store);
            queryOptions.warehouseId = selectedWarehouse?.id;
            const query = convertToQueryParameters(queryOptions);
            const endpoint = '/transport-orders';
            const path = `${import.meta.env.VITE_OTC_TRANSPORTORDER_SERVICE}${endpoint}${query}`;
            const token = getAccessToken(store);
            const apiRole = mapRoleToApiRole(role);
            return fetchReportAndSave(path, token, apiRole).then(() =>
                dispatch(finishedFetchTransportOrderReport()),
            );
        };

export const FINISHED_FETCH_TRANSPORT_ORDER_REPORT = 'FINISHED_FETCH_TRANSPORT_ORDER_REPORT';
export function finishedFetchTransportOrderReport(): FinishedFetchTransportOrderReportAction {
    return {
        type: FINISHED_FETCH_TRANSPORT_ORDER_REPORT,
    };
}

export const STARTED_FETCH_TRANSPORT_ORDER_REPORT = 'STARTED_FETCH_TRANSPORT_ORDER_REPORT';
export function startedFetchTransportOrderReport(): StartedFetchTransportOrderReportAction {
    return {
        type: STARTED_FETCH_TRANSPORT_ORDER_REPORT,
    };
}
