import { Moment } from 'moment';

export interface TransportOrdersState {
    transportOrders: TransportOrders;
    numberOfPendingTransportOrders: number;
    isLoading: boolean;
    isDownloadingReport: boolean;
    selectedTransportOrder: TransportOrder | undefined;
    selectedDateTimeRange: DateTimeRange;
    filterCriterion: FilterCriterion;
    cursor: Cursor | undefined;
    searchFilter: string;
    lastRefresh: Moment;
    isStandingTimeEditModeEnabled: boolean;
}

export type TransportOrders = Array<TransportOrder>;

export interface TransportOrder {
    id: string;
    pickupDate: Moment;
    origin: number;
    orderType: OrderType;
    cargoType?: CargoType;
    licensePlate: string;
    prioritized: boolean;
    dangerousGoods: boolean;
    sameDayPickup?: boolean;
    vehicleType?: VehicleType;
    status?: TransportOrderStatus;
    unloadedDestinations: Array<number>;
    comment: string;
    createdAt: Moment;
    modifiedAt: Moment;
    createdBy?: string;
    modifiedBy?: string;
    needAcknowledgment: Array<AcknowledgmentChangeType>;
    externalReferenceId: string;
    standingOrderPickupTime?: string;
    deliveryPlan: Array<DeliveryPlan>;
    standingTimes: Record<number, StandingTime>;
    carrierName?: string;
}

export interface DeliveryPlan {
    warehouseId: number;
    tonnage?: number;
    volume?: number;
    numberOfPallets?: number;
}

export interface StandingTime {
    start: Moment;
    end?: Moment;
    durationInMinutes?: number;
    holdup?: boolean;
    budgetInMinutes?: number;
    exceedanceInMinutes?: number;
    editableUntil?: Moment;
}

export interface StandingTimeUpdate {
    start: Moment;
    end: Moment;
}

export const UNASSIGNED_LICENSE_PLATE = '';
export const UNASSIGNED_EXTERNAL_REFERENCE_ID = '';

export interface DateTimeRange {
    from?: Moment;
    to?: Moment;
}

export interface Cursor {
    self: { href: string };
    next?: { href: string };
}

export enum FilterCriterion {
    ALL = 'ALL',
    CARRIER_ACTION_REQUIRED = 'CARRIER_ACTION_REQUIRED',
    CARRIER_EN_ROUTE = 'CARRIER_EN_ROUTE',
}

export enum AcknowledgmentChangeType {
    DANGEROUS_GOODS_UPDATED = 'DANGEROUS_GOODS_UPDATED',
    DESTINATIONS_UPDATED = 'DESTINATIONS_UPDATED',
    PRIORITY_UPDATED = 'PRIORITY_UPDATED',
    VEHICLE_TYPE_UPDATED = 'VEHICLE_TYPE_UPDATED',
    TRANSPORT_ORDER_CANCELLED = 'TRANSPORT_ORDER_CANCELLED',
}

export enum OrderType {
    STANDARD = 'STANDARD',
    EXCLUSIVE = 'EXCLUSIVE',
    TRAILER = 'TRAILER',
    STANDING_ORDER = 'STANDING_ORDER',
}

export enum CargoType {
    FULLS = 'FULLS',
    EMPTIES = 'EMPTIES',
    PACKAGING = 'PACKAGING',
    SCRAP = 'SCRAP',
    MISC = 'MISC'
}

export enum VehicleType {
    MEGA_TRAILER = 'MEGA_TRAILER',
    MEDIUM_TRUCK = 'MEDIUM_TRUCK'
}

export enum TransportOrderStatus {
    ORDERED = 'ORDERED',
    PLANNED = 'PLANNED',
    LOADED = 'LOADED',
    PARTIALLY_UNLOADED = 'PARTIALLY_UNLOADED',
    UNLOADED = 'UNLOADED',
    CANCELLED = 'CANCELLED'
}

export const ORDER_TYPE_VALUES: OrderType[] = Object.values(OrderType);
export const CARGO_TYPE_VALUES: CargoType[] = Object.values(CargoType);
export const VEHICLE_TYPE_VALUES: VehicleType[] = Object.values(VehicleType);
export const TRANSPORT_ORDER_STATUS_VALUES: TransportOrderStatus[] = Object.values(TransportOrderStatus);
export const ACKNOWLEDGMENT_CHANGE_TYPES: AcknowledgmentChangeType[] = Object.values(AcknowledgmentChangeType);
