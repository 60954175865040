import { Moment } from 'moment';
import { createSelector } from 'reselect';
import { State } from '../../../types';
import { DateTimeRange, FilterCriterion, TransportOrder, TransportOrders } from '../reducers/transportOrders/types';
import { Warehouses } from '../reducers/warehouses/types';
import { getSelectedWarehouseId } from './Router.selector';

export function isLoadingTransportOrders(a: State): boolean {
    return a.otc.transportOrders.isLoading;
}

export function isDownloadingReport(a: State): boolean {
    return a.otc.transportOrders.isDownloadingReport;
}

export function getTransportOrders(a: State): TransportOrders {
    return a.otc.transportOrders.transportOrders.filter(
        transportOrder => matchesFilter(
            transportOrder, a.otc.warehouses.availableWarehouses, a.otc.transportOrders.searchFilter));
}

export function getNumberOfPendingTransportOrders(a: State): number {
    return a.otc.transportOrders.numberOfPendingTransportOrders;
}

export function getSelectedTransportOrderId(a: State): string | undefined {
    return a.otc.transportOrders.selectedTransportOrder?.id;
}

export function getSelectedTransportOrder(a: State): TransportOrder | undefined {
    return a.otc.transportOrders.selectedTransportOrder;
}

export function getSelectedDateTimeRange(a: State): DateTimeRange {
    return a.otc.transportOrders.selectedDateTimeRange;
}

export function getFilterCriterion(a: State): FilterCriterion | undefined {
    return a.otc.transportOrders.filterCriterion;
}

export function getSearchFilter(a: State): string {
    return a.otc.transportOrders.searchFilter;
}

export function getNextLink(a: State): string | undefined {
    return a.otc.transportOrders.cursor?.next?.href;
}

export function getLastRefresh(a: State): Moment {
    return a.otc.transportOrders.lastRefresh;
}

export const getIsStandingTimeEditModeEnabled = (state: State) =>
    state.otc.transportOrders.isStandingTimeEditModeEnabled;

export const isOutgoingTransportOrder =
    createSelector(getSelectedTransportOrder, getSelectedWarehouseId, (transportOrder, warehouseId) => {
        if (transportOrder === undefined || warehouseId === undefined) {
            return undefined;
        }
        return transportOrder['origin'] === warehouseId;
    });

const matchesFilter = (transportOrder: TransportOrder, warehouses: Warehouses, filter: String) => {
    const originWarehouse = warehouses.find(warehouse => warehouse.id === transportOrder.origin);
    const matchComponents = [
        transportOrder.origin,
        originWarehouse?.name || '',
        originWarehouse?.address || '',
        transportOrder.licensePlate,
    ];
    const matchString = matchComponents.join(' ').toLowerCase();
    const filterWords = filter.toLowerCase().split(' ');
    return filterWords.every(filterWord => matchString.includes(filterWord));
};
