import { Role } from '../../types';

export enum ValidColumn {
    MARKERS = 'MARKERS',
    DIRECTION = 'DIRECTION',
    ID = 'ID',
    STATUS = 'STATUS',
    ORIGIN = 'ORIGIN',
    DESTINATIONS = 'DESTINATIONS',
    PICKUP_DATE = 'PICKUP_DATE',
    LICENSE_PLATE = 'LICENSE_PLATE',
    EXTERNAL_REFERENCE_ID = 'EXTERNAL_REFERENCE_ID',
    CARGO_TYPE = 'CARGO_TYPE',
    MASS_IN_KG = 'MASS_IN_KG',
    VOLUME_IN_M3 = 'VOLUME_IN_M3',
    NUMBER_OF_PALLETS = 'NUMBER_OF_PALLETS',
    PRIORITIZED = 'PRIORITIZED',
    ORDER_TYPE = 'ORDER_TYPE',
    VEHICLE_TYPE = 'VEHICLE_TYPE',
    DANGEROUS_GOODS = 'DANGEROUS_GOODS',
    STANDING_TIME = 'STANDING_TIME',
    STANDING_TIME_EXCEEDANCE = 'STANDING_TIME_EXCEEDANCE',
    CARRIER_NAME = 'CARRIER_NAME',
}

export interface ColumnSetting {
    id: ValidColumn;
    hidden: boolean;
    width: number | undefined; // in pixel, undefined corresponds to automatic settings
}

// eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
export type RoleDefaultSettings = { [key in ValidColumn]: Omit<ColumnSetting, 'id'> };

export interface TableSettingsState {
    defaultSettings: ColumnSetting[];
    currentSettings: ColumnSetting[];
    lastRole: Role;
}
