import {
    AcknowledgeChangesRequest,
    DeliveryPlanRequest,
    FetchTransportOrderQueryOptions,
    StandingTimeUpdateRequest,
    TransportOrderCancellationRequest,
    TransportOrderCarrierUpdateRequest,
    WarehouseEmployeeTransportOrderUpdateRequest,
} from '../../../api/types';
import {
    DeliveryPlan,
    FilterCriterion,
    StandingTimeUpdate,
    TransportOrder,
    TransportOrderStatus,
} from '../../reducers/transportOrders/types';
import { Role } from '../../types';
import { ApiRole } from '../../actions/types';

const transportOrdersNotEnRoute = [
    TransportOrderStatus.ORDERED,
    TransportOrderStatus.PLANNED,
    TransportOrderStatus.CANCELLED,
    TransportOrderStatus.UNLOADED,
];

export const convertFilterCriterionToQuery = (filterCriterion?: FilterCriterion): string => {
    switch (filterCriterion) {
        case FilterCriterion.ALL:
            return '';
        case FilterCriterion.CARRIER_ACTION_REQUIRED:
            return `criterion=${filterCriterion}`;
        case FilterCriterion.CARRIER_EN_ROUTE:
            return transportOrdersNotEnRoute.map(it => `excluded_status=${it}`).join('&');
        default:
            return '';

    }
};

export const convertToQueryParameters = (queryOptions: FetchTransportOrderQueryOptions): string => {
    const warehouseIdQueryParameter = queryOptions.warehouseId && `warehouse_id=${queryOptions.warehouseId}`;
    const excludedStatusesQueryParameters = queryOptions.exclude &&
        queryOptions.exclude.map(status => `excluded_status=${status}`).join('&');
    const pickupDateFromQueryParameter = queryOptions.pickupDateFrom && `pickup_date_from=${queryOptions.pickupDateFrom}`;
    const pickupDateToQueryParameter = queryOptions.pickupDateTo && `pickup_date_to=${queryOptions.pickupDateTo}`;
    const criterionQueryParameter = convertFilterCriterionToQuery(queryOptions.filterCriterion);
    const limitQueryParameter = queryOptions.limit && `limit=${queryOptions.limit}`;

    const listOfPresentQueryParameters = [
        warehouseIdQueryParameter,
        excludedStatusesQueryParameters,
        pickupDateFromQueryParameter,
        pickupDateToQueryParameter,
        criterionQueryParameter,
        limitQueryParameter]
        .filter(parameter => !!parameter)
        .join('&');

    return listOfPresentQueryParameters.length > 0 ? `?${listOfPresentQueryParameters}` : '';
};

export const mapToTransportOrderCancellation = (): TransportOrderCancellationRequest => ({
    status: TransportOrderStatus.CANCELLED,
});

export const acknowledgeChangesRequestBody = (): AcknowledgeChangesRequest => ({
    need_acknowledgment: [], // eslint-disable-line  @typescript-eslint/naming-convention
});

/* eslint-disable @typescript-eslint/naming-convention */
export const mapPartialTransportOrderToTransportOrderUpdateRequest =
    // eslint-disable-next-line complexity
    (
        transportOrderUpdate: Partial<TransportOrder>,
    ): TransportOrderCarrierUpdateRequest | WarehouseEmployeeTransportOrderUpdateRequest => {
        return {
            ...(transportOrderUpdate.status !== undefined &&
                { status: transportOrderUpdate.status }),
            ...(transportOrderUpdate.licensePlate !== undefined &&
                { license_plate: transportOrderUpdate.licensePlate.trim() }),
            ...(transportOrderUpdate.sameDayPickup !== undefined &&
                { same_day_pickup: transportOrderUpdate.sameDayPickup }),
            ...(transportOrderUpdate.origin !== undefined &&
                { origin: transportOrderUpdate.origin }),
            ...(transportOrderUpdate.comment !== undefined &&
                { comment: transportOrderUpdate.comment }),
            ...(transportOrderUpdate.cargoType !== undefined &&
                { cargo_type: transportOrderUpdate.cargoType }),
            ...(transportOrderUpdate.orderType !== undefined &&
                { order_type: transportOrderUpdate.orderType }),
            ...(transportOrderUpdate.pickupDate !== undefined &&
                { pickup_date: transportOrderUpdate.pickupDate.format('YYYY-MM-DD') }),
            ...(transportOrderUpdate.prioritized !== undefined &&
                { prioritized: transportOrderUpdate.prioritized }),
            ...(transportOrderUpdate.dangerousGoods !== undefined &&
                { dangerous_goods: transportOrderUpdate.dangerousGoods }),
            ...(transportOrderUpdate.vehicleType !== undefined &&
                { vehicle_type: transportOrderUpdate.vehicleType }),
            ...(transportOrderUpdate.externalReferenceId !== undefined &&
                { external_reference_id: transportOrderUpdate.externalReferenceId.trim() }),
            ...(transportOrderUpdate.deliveryPlan !== undefined &&
                {
                    delivery_plan: mapDeliveryPlanToDeliveryPlanRequest(transportOrderUpdate.deliveryPlan),
                }),
        };
    };

export const mapDeliveryPlanToDeliveryPlanRequest = (
    deliveryPlan: Array<DeliveryPlan>,
): Array<DeliveryPlanRequest> => {
    return deliveryPlan.map(item => ({
        warehouse_id: item.warehouseId,
        volume: item.volume,
        tonnage: item.tonnage ? item.tonnage / 1000 : 0,
        number_of_pallets: item.numberOfPallets,
    }) as DeliveryPlanRequest);
};

export const mapStandingTimeUpdateToStandingTimeUpdateRequest = (
    standingTimeUpdate: StandingTimeUpdate,
): StandingTimeUpdateRequest => ({
    start: standingTimeUpdate.start.toISOString(),
    end: standingTimeUpdate.end?.toISOString(),
});

export const mapRoleToApiRole = (role: Role): ApiRole => {
    switch (role) {
        case Role.AUDITOR:
            return ApiRole.AUDITOR;
        case Role.CARRIER:
            return ApiRole.CARRIER;
        case Role.WAREHOUSE_EMPLOYEE:
            return ApiRole.WAREHOUSE_EMPLOYEE;
        default:
            throw new Error('Unknown role');
    }
};
