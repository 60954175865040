export const WarehouseEmployeeLoadingProgressFormConfig = {
    name: 'warehouse-employee-transport-order-status-bar',
    valueLabel: {
        holdup: 'holdup' as const,
        standingTime: {
            date: 'standingTimeDate' as const,
            time: 'standingTimeTime' as const,
        },
    },
};
