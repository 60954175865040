/* eslint-disable @typescript-eslint/naming-convention */
import moment, { Moment } from 'moment';
import { neverReachedFor } from '../../ts-utils';
import { DeliveryPlanForm, TransportOrderValidFormData } from '../app/components/transportOrders/form/types';
import {
    WarehouseEmployeeLoadingProgressFormData,
} from '../app/components/views/warehouseEmployee/transportOrders/sidebars/editSidebar/loadingProgressForm/types';
import {
    WarehouseEmployeeLoadingProgressFormConfig,
} from '../app/components/views/warehouseEmployee/transportOrders/sidebars/editSidebar/loadingProgressForm/WarehouseEmployeeLoadingProgressFormConfig';
import { mapDeliveryPlanToDeliveryPlanRequest } from '../app/mapper/api/transportOrderToExternal';
import { DeliveryPlan, OrderType, StandingTime, TransportOrder } from '../app/reducers/transportOrders/types';
import { LoadingProgress } from '../app/selectors/LoadingProgress.selectors';
import {
    isLoadingStartedPostRequest,
    LoadingEndedPostRequest,
    LoadingInformationPostRequest,
    LoadingStartedPostRequest,
    TransportOrderRequest,
} from './types';

const validateToNotBeUndefined = (value: any, valueName: string): any => {
    if (value === undefined) {
        throw Error('Error trying during the conversion of the creation-form to transport order' +
            `for value: ${valueName}`);
    }
    return value;
};

const formatToIso8601Date =
    (date: Moment): string => moment(date).format('YYYY-MM-DD');

// TODO: split into 2 mapper:
//  form values -> TransportOrder and TransportOrder to external request
export const mapTransportOrderFormDataToTransportOrderRequest =
    (transportOrderFormData: TransportOrderValidFormData): TransportOrderRequest => {
        const transportOrder = {
            id: validateToNotBeUndefined(transportOrderFormData.id, 'id'),
            origin: validateToNotBeUndefined(transportOrderFormData.origin.id, 'origin id'),
            order_type: validateToNotBeUndefined(transportOrderFormData.orderType, 'order type'),
            cargo_type: validateToNotBeUndefined(transportOrderFormData.cargoType, 'cargo type'),
            vehicle_type: validateToNotBeUndefined(transportOrderFormData.vehicleType, 'vehicle type'),
            pickup_date:
                validateToNotBeUndefined(formatToIso8601Date(transportOrderFormData.pickupDate), 'pickup date'),
            prioritized: validateToNotBeUndefined(transportOrderFormData.prioritized, 'prioritized'),
            dangerous_goods: validateToNotBeUndefined(transportOrderFormData.dangerousGoods, 'dangerous goods'),
            comment: validateToNotBeUndefined(transportOrderFormData.comment, 'comment'),
            license_plate: validateToNotBeUndefined(transportOrderFormData.licensePlate, 'licensePlate'),
            external_reference_id: validateToNotBeUndefined(transportOrderFormData.externalReferenceId, 'externalReferenceId'),
            delivery_plan: [],
        };
        return enhanceTransportOrderWithDeliveryPlan(transportOrderFormData, transportOrder);
    };

const enhanceTransportOrderWithDeliveryPlan = (
    transportOrderFormData: TransportOrderValidFormData,
    transportOrderToBeEnhanced: TransportOrderRequest,
): TransportOrderRequest => {
    validateDeliveryPlan(transportOrderFormData);
    const deliveryPlanModel = mapDeliveryPlanFormToDeliveryPlan(transportOrderFormData.deliveryPlan);
    transportOrderToBeEnhanced.delivery_plan = mapDeliveryPlanToDeliveryPlanRequest(deliveryPlanModel);
    return transportOrderToBeEnhanced;
};

// eslint-disable-next-line complexity
const validateDeliveryPlan = (transportOrderFormData: TransportOrderValidFormData) => {
    if (transportOrderFormData.deliveryPlan === undefined ||
        transportOrderFormData.deliveryPlan.length === 0 ||
        (transportOrderFormData.deliveryPlan.length > 0 &&
            (transportOrderFormData.deliveryPlan[0].warehouse === undefined ||
                transportOrderFormData.deliveryPlan[0].warehouse.id === undefined ||
                transportOrderFormData.totalVolume === undefined ||
                transportOrderFormData.totalTonnage === undefined ||
                transportOrderFormData.totalNumberOfPallets === undefined ||
                transportOrderFormData.totalVolume === 0 ||
                transportOrderFormData.totalTonnage === 0 ||
                transportOrderFormData.totalNumberOfPallets === 0))) {
        throw Error('Error trying during the conversion of the creation-form to transport order of value: deliveryPlan');
    }

    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < transportOrderFormData.deliveryPlan.length; i++) {
        const deliveryPlan = transportOrderFormData.deliveryPlan[i];
        if (deliveryPlan.warehouse === undefined ||
            deliveryPlan.warehouse.id === undefined ||
            deliveryPlan.volume === undefined ||
            deliveryPlan.tonnage === undefined ||
            deliveryPlan.numberOfPallets === undefined ||
            deliveryPlan.volume === 0 ||
            deliveryPlan.tonnage === 0 ||
            deliveryPlan.numberOfPallets === 0) {
            throw Error('Error trying during the conversion of the creation-form to transport order of value: deliveryPlan items');
        }
    }
};

export const mapDeliveryPlanFormToDeliveryPlan = (deliveryPlan: Array<DeliveryPlanForm>): Array<DeliveryPlan> => {
    return deliveryPlan.map(item => ({
        warehouseId: item.warehouse.id,
        volume: item.volume,
        tonnage: item.tonnage,
        numberOfPallets: item.numberOfPallets,
    }) as DeliveryPlan);
};

export enum LoadingType {
    LOADING_STARTED = 'LOADING_STARTED',
    UNLOADING_STARTED = 'UNLOADING_STARTED',
    LOADING_ENDED = 'LOADING_ENDED',
    UNLOADING_ENDED = 'UNLOADING_ENDED',
}

const mapFormDataTime = (formDataTime: string | undefined): string => {
    if (formDataTime === undefined) {
        throw Error('Received invalid time undefined when submitting');
    } else if (formDataTime === '') {
        return moment().format('HH:mm');
    } else {
        return formDataTime;
    }
};

export const mapFromLoadingProgressFormDataToLoadingInformationPostRequest = (
    formData: WarehouseEmployeeLoadingProgressFormData,
    transportOrder: TransportOrder,
    warehouseId: number,
    loadingProgress: LoadingProgress,
): LoadingInformationPostRequest => {
    const holdup = formData[WarehouseEmployeeLoadingProgressFormConfig.valueLabel.holdup] === 'true';
    const date = formData[WarehouseEmployeeLoadingProgressFormConfig.valueLabel.standingTime.date];
    const time = mapFormDataTime(formData[WarehouseEmployeeLoadingProgressFormConfig.valueLabel.standingTime.time]);

    const datetime = moment(`${date}T${time}`);
    const loadingType = getLoadingInfoType(transportOrder, warehouseId, loadingProgress);

    switch (loadingType) {
        case LoadingType.LOADING_STARTED:
            return {
                warehouse_id: warehouseId,
                standing_time_start: datetime.toISOString(),
                holdup,
                loaded: false,
            };
        case LoadingType.LOADING_ENDED:
            return {
                warehouse_id: warehouseId,
                standing_time_end: datetime.toISOString(),
                holdup,
                loaded: true,
            };
        case LoadingType.UNLOADING_STARTED:
            return {
                warehouse_id: warehouseId,
                standing_time_start: datetime.toISOString(),
                holdup,
                unloaded: false,
            };
        case LoadingType.UNLOADING_ENDED:
            return {
                warehouse_id: warehouseId,
                standing_time_end: datetime.toISOString(),
                holdup,
                unloaded: true,
            };
        default:
            return neverReachedFor(loadingType);
    }
};

const getLoadingInfoType = (
    transportOrder: TransportOrder,
    warehouseId: number,
    loadingProgress: LoadingProgress,
): LoadingType => {
    if (transportOrder.origin === warehouseId) {
        if (loadingProgress === LoadingProgress.NOT_YET_STARTED) {
            return LoadingType.LOADING_STARTED;
        } else if (loadingProgress === LoadingProgress.STARTED) {
            return LoadingType.LOADING_ENDED;
        }
    } else if (transportOrder.deliveryPlan.map(deliveryPlan => deliveryPlan.warehouseId)
        .includes(warehouseId)) {
        if (loadingProgress === LoadingProgress.NOT_YET_STARTED) {
            return LoadingType.UNLOADING_STARTED;
        } else if (loadingProgress === LoadingProgress.STARTED) {
            return LoadingType.UNLOADING_ENDED;
        }
    }
    throw Error('LoadingType could not be determined. This should never happen.');
};

export const mapFromLoadingInformationPostRequestToStandingTimeModel =
    (loadingInformation: LoadingInformationPostRequest, transportOrder: TransportOrder): StandingTime => {
        const transportOrderStandingTime = transportOrder.standingTimes[loadingInformation.warehouse_id];
        const standingTimeStart = (loadingInformation as LoadingStartedPostRequest).standing_time_start;
        const standingTimeEnd = (loadingInformation as LoadingEndedPostRequest).standing_time_end;

        const start = standingTimeStart !== undefined ?
            moment(standingTimeStart).local() : transportOrderStandingTime!.start;
        let end = standingTimeEnd !== undefined ?
            moment(standingTimeEnd).local() : transportOrderStandingTime?.end;

        if (isLoadingStartedPostRequest(loadingInformation) && transportOrder.orderType === OrderType.TRAILER) {
            end = start.clone().add(30, 'minutes');
        }

        const durationInMinutes = calculateDurationInMinutes(start, end);

        return {
            start,
            end,
            durationInMinutes,
            holdup: loadingInformation.holdup === true || transportOrderStandingTime?.holdup === true,
        };
    };

export const calculateDurationInMinutes = (start: Moment, end: Moment | undefined): number | undefined => {
    if (end !== undefined) {
        const duration = moment.duration(end.diff(start));
        return Math.round(duration.asMinutes());
    }
    return undefined;
};
