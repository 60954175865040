import Joi from '@hapi/joi';
import { reportErrorToSentry } from '../../../../configuration/setup/sentry';
import { Warehouse } from '../../reducers/warehouses/types';

export interface WarehouseExternal {
    id: number;
    name: string;
    address: string;
    active: boolean;
}

export const WarehouseExternalSchema = Joi.object({
    id: Joi.number().required(),
    name: Joi.string().required(),
    address: Joi.string().required().allow(''),
    active: Joi.boolean().required(),
}).options({ stripUnknown: true });

interface WarehouseExternalCollection {
    items: WarehouseExternal[];
}

export const mapWarehouseExternalToWarehouse = (warehouseExternal: unknown): Warehouse => {
    if (proveIsWarehouseExternal(warehouseExternal)) {
        return {
            id: warehouseExternal.id,
            name: warehouseExternal.name,
            address: warehouseExternal.address,
            active: warehouseExternal.active,
        };
    }

    throw new Error('Invalid warehouse response received from backend');
};

const proveIsWarehouseExternal =
    (warehouseResponseItem: unknown): warehouseResponseItem is WarehouseExternal => {
        const { error } = WarehouseExternalSchema.validate(warehouseResponseItem);
        if (error) {
            reportErrorToSentry(error);
        }
        return !error;
    };

const WarehouseExternalCollectionSchema = Joi.object({
    items: Joi.array(),
}).options({ stripUnknown: true });

const proveIsWarehouseExternalCollection = (dto: unknown): dto is WarehouseExternalCollection => {
    const { error } = WarehouseExternalCollectionSchema.validate(dto);
    if (error) {
        reportErrorToSentry(error);
    }
    return !error;
};

export const mapWarehouseResponseToWarehouses = (warehouseResponse: unknown): Warehouse[] => {
    if (proveIsWarehouseExternalCollection(warehouseResponse)) {
        return warehouseResponse.items.map(mapWarehouseExternalToWarehouse);
    }
    throw new Error('Invalid warehouse collection response received from backend');
};
