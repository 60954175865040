import { State } from '../../../types';
import { getSelectedWarehouseId } from './Router.selector';
import { getSelectedTransportOrder } from './TransportOrders.selector';
import { TransportOrderStatus } from '../reducers/transportOrders/types';

export const isTransportOrderLoadable = (state: State): boolean => {
    const selectedWarehouse = getSelectedWarehouseId(state);
    const selectedTransportOrder = getSelectedTransportOrder(state);
    const loadableStatuses = [TransportOrderStatus.ORDERED, TransportOrderStatus.PLANNED];

    if (selectedWarehouse !== selectedTransportOrder?.origin) {
        return false;
    }

    return selectedTransportOrder?.status !== undefined && loadableStatuses.includes(selectedTransportOrder?.status);
};

export enum LoadingProgress {
    NOT_YET_STARTED = 'NOT_YET_STARTED',
    STARTED = 'STARTED',
    ENDED = 'ENDED',
}

export const getLoadingProgress = (state: State): LoadingProgress => {
    const selectedTransportOrder = getSelectedTransportOrder(state);
    const selectedWarehouseId = getSelectedWarehouseId(state);

    if (selectedWarehouseId === undefined) {
        return LoadingProgress.NOT_YET_STARTED;
    }

    const standingTime = selectedTransportOrder?.standingTimes[selectedWarehouseId];

    if (standingTime === undefined) {
        return LoadingProgress.NOT_YET_STARTED;
    } else if (standingTime.end === undefined) {
        return LoadingProgress.STARTED;
    } else {
        return LoadingProgress.ENDED;
    }
};
