/* eslint-disable @typescript-eslint/naming-convention */
import {
    CargoType,
    FilterCriterion,
    OrderType,
    TransportOrderStatus,
    VehicleType,
} from '../app/reducers/transportOrders/types';

export interface TransportOrderRequest {
    id: string;
    origin: number;
    order_type: OrderType;
    cargo_type: CargoType;
    prioritized: boolean;
    dangerous_goods: boolean;
    vehicle_type: VehicleType;
    pickup_date: string;
    comment?: string;
    license_plate: string;
    external_reference_id?: string;
    delivery_plan: Array<DeliveryPlanRequest>;
}

export interface WarehouseEmployeeTransportOrderUpdateRequest {
    origin?: number;
    order_type?: OrderType;
    cargo_type?: CargoType;
    prioritized?: boolean;
    dangerous_goods?: boolean;
    vehicle_type?: VehicleType;
    pickup_date?: string;
    comment?: string;
    delivery_plan?: Array<DeliveryPlanRequest>;
}

export interface DeliveryPlanRequest {
    warehouse_id: number;
    tonnage?: number;
    volume?: number;
    number_of_pallets?: number;
}

export interface TransportOrderCancellationRequest {
    status: typeof TransportOrderStatus.CANCELLED;
}

export interface TransportOrderCarrierUpdateRequest {
    status?: TransportOrderStatus;
    same_day_pickup?: boolean;
    license_plate?: string;
    external_reference_id?: string;
}

export interface FetchTransportOrderQueryOptions {
    warehouseId?: number;
    exclude?: Array<TransportOrderStatus>;
    pickupDateFrom?: string;
    pickupDateTo?: string;
    filterCriterion?: FilterCriterion;
    cursor?: string;
    limit?: number;
}

export interface AcknowledgeChangesRequest {
    need_acknowledgment: Array<string>;
}

export interface LoadingStartedPostRequest {
    warehouse_id: number;
    standing_time_start: string;
    holdup: boolean;
    loaded: false;
}

export interface LoadingEndedPostRequest {
    warehouse_id: number;
    standing_time_end?: string;
    holdup?: boolean;
    loaded: true;
}

export interface UnloadingStartedPostRequest {
    warehouse_id: number;
    standing_time_start: string;
    holdup: boolean;
    unloaded: false;
}

export interface UnloadingEndedPostRequest {
    warehouse_id: number;
    standing_time_end?: string;
    holdup?: boolean;
    unloaded: true;
}

export interface StandingTimeUpdateRequest {
    start: string;
    end: string;
}

export type LoadingInformationPostRequest =
    LoadingStartedPostRequest | LoadingEndedPostRequest | UnloadingStartedPostRequest | UnloadingEndedPostRequest;

export const isLoadingStartedPostRequest = (
    loadingInformationPostRequest: LoadingInformationPostRequest,
): loadingInformationPostRequest is LoadingStartedPostRequest =>
    (loadingInformationPostRequest as Partial<LoadingStartedPostRequest>).loaded === false;

export const isLoadingEndedPostRequest = (
    loadingInformationPostRequest: LoadingInformationPostRequest,
): loadingInformationPostRequest is LoadingEndedPostRequest =>
    (loadingInformationPostRequest as LoadingEndedPostRequest).loaded;

export const isUnloadingEndedPostRequest = (
    loadingInformationPostRequest: LoadingInformationPostRequest,
): loadingInformationPostRequest is UnloadingEndedPostRequest =>
    (loadingInformationPostRequest as UnloadingEndedPostRequest).unloaded;
